<template>
    <div>
        <ts-page-title
            :title="$t('leaveSubItem.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveSubItem.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('leaveSubItem.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <div class="overflow-auto">
                <ts-table
                    :loading="this.loading"
                    class="tw-whitespace-nowrap "
                    :records="resources"
                    :columns="columns"
                    :rowGroup="{
                        field: 'leave_type.leave_type_en'
                    }"
                >
                    <template v-slot="{ record }">
                        <td width="30%">
                            <span>
                                {{ record.leave_sub_item }}
                            </span>
                        </td>
                        <td width="20%" class="tw-text-center">
                            <span>
                                {{ record.max_day_allow }}
                            </span>
                        </td>
                        <td width="20%" class="tw-text-center">
                            <span>
                                {{ record.max_request_per_time }}
                            </span>
                        </td>
                        <td width="20%" class="tw-text-center">
                            <span>
                                {{ record.allow_for }}
                            </span>
                        </td>
                        <td
                            class="tw-align-middle tw-text-center tw-min-w-0"
                            width="10%"
                        >
                            <a
                                href="#"
                                class="text-primary ml-2"
                                @click.prevent="onEdit(record)"
                                v-tooltip="$t('edit')"
                            >
                                <Icon type="ios-create" size="20" />
                            </a>
                            <Poptip
                                confirm
                                :title="$t('areYouSure')"
                                @on-ok="onDelete(record)"
                                :transfer="true"
                                :ok-text="$t('yes')"
                                :cancel-text="$t('no')"
                            >
                                <a
                                    class="ml-2 text-danger"
                                    v-tooltip="$t('delete')"
                                    :disabled="record._deleting"
                                >
                                    <i
                                        class="fas fa-circle-notch fa-spin"
                                        v-if="record._deleting"
                                    ></i>
                                    <Icon type="ios-trash" size="20" v-else />
                                </a>
                            </Poptip>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>

            <!-- form-action -->
            <Modal
                v-model="showForm"
                :width="600"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('leaveSubItem.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";
export default {
    name: "leave-sub-itemIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/leaveSubItem", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.humanResource.leaveSubItem.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/leaveSubItem/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/leaveSubItem/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("leaveSubItem.leaveSubitem")
                },
                {
                    name: this.$t("leaveSubItem.maxAllowDay"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveSubItem.maxRequesPerTime"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveSubItem.allowFor"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/leaveSubItem/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit(
                "humanResource/leaveSubItem/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.$store.commit("humanResource/leaveSubItem/SET_EDIT_DATA", {});
            this.showForm = false;
            this.$refs.form_action.clearInput();
        },
        addNew() {
            this.$store.commit("humanResource/leaveSubItem/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "humanResource/leaveSubItem/destroy",
                    record.leave_sub_item_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE SUB ITEM",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/leaveSubItem/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
