<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveSubItem.leaveType")
                }}</label>
                <Select
                    v-model.number="model.leave_type_id"
                    :class="{
                        'ivu-form-item-error': errors.has('leave_type_id')
                    }"
                >
                    <Option
                        v-for="(leaveType, index) in leaveTypes"
                        :key="index"
                        :value="leaveType.leave_type_id"
                        >{{ leaveType.leave_type_en }}
                    </Option>
                </Select>

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_type_id')"
                >
                    {{ errors.first("leave_type_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveSubItem.leaveSubitem")
                }}</label>
                <input
                    v-model.trim="model.leave_sub_item"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('leave_sub_item') }"
                />

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_sub_item')"
                >
                    {{ errors.first("leave_sub_item") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveSubItem.maxAllowDay")
                }}</label>
                <input
                    v-model.trim.number="model.max_day_allow"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('max_day_allow') }"
                />

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('max_day_allow')"
                >
                    {{ errors.first("max_day_allow") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveSubItem.maxRequesPerTime")
                }}</label>
                <input
                    v-model.trim.number="model.max_request_per_time"
                    type="number"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('max_request_per_time')
                    }"
                />

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('max_request_per_time')"
                >
                    {{ errors.first("max_request_per_time") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveSubItem.allowFor")
                }}</label>
                <Select
                    v-model="model.allow_for"
                    :class="{
                        'ivu-form-item-error': errors.has('allow_for')
                    }"
                >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Both">Both</Option>
                </Select>

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('allow_for')"
                >
                    {{ errors.first("allow_for") }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "leave-sub-itemForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                leave_type_id: null,
                leave_sub_item: null,
                max_day_allow: null,
                max_request_per_time: null,
                allow_for: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/leaveSubItem", ["edit_data", "leaveTypes"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("humanResource/leaveSubItem", ["getLeaveType"]),
        async fetchResource() {
            this.loading = true;
            await this.getLeaveType();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/leaveSubItem/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/leaveSubItem/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/leaveSubItem/update", {
                    id: this.edit_data.leave_sub_item_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.leave_type_id = this.edit_data.leave_type_id;
                this.model.leave_sub_item = this.edit_data.leave_sub_item;
                this.model.max_day_allow = this.edit_data.max_day_allow;
                this.model.max_request_per_time = this.edit_data.max_request_per_time;
                this.model.allow_for = this.edit_data.allow_for;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.leave_type_id = null;
            this.model.leave_sub_item = null;
            this.model.max_day_allow = null;
            this.model.max_request_per_time = null;
            this.model.allow_for = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE SUB ITEM",
                desc: not.text
            });
        }
    }
};
</script>
