var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('leaveSubItem.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('leaveSubItem.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t("addNew"))+" ")]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('leaveSubItem.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"overflow-auto"},[_c('ts-table',{staticClass:"tw-whitespace-nowrap ",attrs:{"loading":this.loading,"records":_vm.resources,"columns":_vm.columns,"rowGroup":{
                    field: 'leave_type.leave_type_en'
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var record = ref.record;
return [_c('td',{attrs:{"width":"30%"}},[_c('span',[_vm._v(" "+_vm._s(record.leave_sub_item)+" ")])]),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[_c('span',[_vm._v(" "+_vm._s(record.max_day_allow)+" ")])]),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[_c('span',[_vm._v(" "+_vm._s(record.max_request_per_time)+" ")])]),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[_c('span',[_vm._v(" "+_vm._s(record.allow_for)+" ")])]),_c('td',{staticClass:"tw-align-middle tw-text-center tw-min-w-0",attrs:{"width":"10%"}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(record)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(record)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":record._deleting}},[(record._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])],1)]}}])})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"width":600,"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('leaveSubItem.pageTitle')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }